import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import { mediaQueries, screenSizes } from "../styles/queries";
import { grid, colors, distances, fontStyles } from "../styles/variables";
import Footer from "../components/Footer";

const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  min-height: var(--100dvh);
  ${grid.outer};
  max-width: ${screenSizes.maxWideScreen}px;
  color: inherit;
  cursor: inherit;
`;
const Inner = styled.div`
  ${grid.innerAutoRows};
  min-width: 100%;
  min-height: var(--100dvh);
  margin: auto;
  color: inherit;
`;

const Paragraph = styled.p`
  margin-block-start: ${distances.p0};
  margin: 0;
  margin-bottom: ${distances.p1};
  color: inherit;
`;

const TinyTitle = styled.h4`
  /* color: ${colors.gray}; */
  opacity: 0.9;
  font-weight: 400;
  font-size: 0.8rem;
  margin-top: 0;
  margin-bottom: 0;
  margin-block-end: 4px;
  color: inherit;

  ${fontStyles.capitals}
`;

const TextBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: inherit;

  /* ${(props) => props.gridArea && `grid-area: ${props.gridArea};`}
  ${mediaQueries.medium} {
    ${(props) => props.gridAreaMedium && `grid-area: ${props.gridAreaMedium};`}
  }
  ${mediaQueries.small} {
    ${(props) => props.gridAreaSmall && `grid-area: ${props.gridAreaSmall};`}
  }
  ${mediaQueries.standard} {
    ${(props) =>
    props.gridAreaStandard &&
    `grid-area: ${props.gridAreaStandard};
    `}
  }
  ${mediaQueries.desktop} {
    ${(props) =>
    props.gridAreaDesktop && `grid-area: ${props.gridAreaDesktop};`}
  } */
  ${(props) => props.gridRow && `grid-row: ${props.gridRow};`}
  ${mediaQueries.medium} {
    ${(props) => props.gridRowMedium && `grid-row: ${props.gridRowMedium};`}
  }
  ${(props) => props.gridColumn && `grid-column: ${props.gridColumn};`}
  ${mediaQueries.medium} {
    ${(props) =>
      props.gridColumnMedium && `grid-column: ${props.gridColumnMedium};`}
  }
  ${mediaQueries.small} {
    ${(props) =>
      props.gridColumnSmall && `grid-column: ${props.gridColumnSmall};`}
  }
  ${mediaQueries.standard} {
    ${(props) =>
      props.gridColumnStandard &&
      `grid-column: ${props.gridColumnStandard};
    `}
  }
  ${mediaQueries.desktop} {
    ${(props) =>
      props.gridColumnDesktop && `grid-column: ${props.gridColumnDesktop};`}
  }
`;

const textPageTemplate = ({ title, description, children }) => {
  const helmetProps = { title, description };
  return (
    <Layout singlePageFooter>
      <Helmet {...helmetProps} />
      <Wrapper>
        <Inner>
          <Navbar
            title={title}
            services={[title]}
            // services={[frontmatter?.title, ...frontmatter?.services]}
            visible={true}
          />
          {children}
        </Inner>
      </Wrapper>
    </Layout>
  );
};

export { textPageTemplate as default, Paragraph, TinyTitle, TextBox };
