import React from "react";
import styled from "styled-components";
import TextPageTemplate, {
  TextBox,
  Paragraph,
  TinyTitle,
} from "../templates/textPageTemplate";

const Imprint = () => {
  return (
    <TextPageTemplate
      title={"Imprint"}
      description={
        "Theodor Hillmann, an experienced interaction and interface designer, specializes in creating engaging and user-centered web experiences for forward-thinking companies. "
      }
    >
      <TextBox
        gridColumn={"2/4"}
        gridColumnDesktop={"2/3"}
        gridColumnMedium={"2/5"}
        gridRow={3}
      >
        <TinyTitle>Address:</TinyTitle>
        <Paragraph>
          Obentrautstraße 71 <br />
          Atelier OBEN <br />
          10963, Berlin
        </Paragraph>

        <TinyTitle>Email:</TinyTitle>
        <Paragraph>Imprint@theodorhillmann.de</Paragraph>

        <TinyTitle>
          Responsible for the content of this website according to § 55 RStV:
        </TinyTitle>
        <Paragraph>Theodor Hillmann</Paragraph>

        <TinyTitle>Notice of liability: </TinyTitle>
        <Paragraph>
          Despite careful control of the content, we do not assume any liability
          for the content of external links. The operators of the linked pages
          are solely responsible for their content. All rights reserved. The
          content of this website, including text, images, and design, is
          protected by copyright. The reproduction, modification, distribution,
          or storage of the content without the prior written consent of the
          author is prohibited.
        </Paragraph>
      </TextBox>
      <TextBox
        gridColumn={"4/6"}
        gridColumnDesktop={"3/4"}
        gridColumnMedium={"2/5"}
        gridRow={3}
        gridRowMedium={4}
      >
        <TinyTitle>Data Protection:</TinyTitle>
        <Paragraph>
          We take the protection of your personal data very seriously. We treat
          your personal data confidentially and in accordance with the legal
          data protection regulations as well as this privacy policy. The use of
          our website is generally possible without providing personal data. As
          far as personal data (such as name, address, or email addresses) is
          collected on our website, this is always done on a voluntary basis as
          far as possible. This data will not be passed on to third parties
          without your explicit consent. We point out that data transmission
          over the Internet (e.g. communication by email) can have security
          gaps. A complete protection of data against unauthorized access by
          third parties is not possible. As the website operator, we reserve the
          right to take legal action in the event of the unsolicited sending of
          advertising information, for example through spam emails.
        </Paragraph>
      </TextBox>
    </TextPageTemplate>
  );
};

export default Imprint;
